import '../../styles/globals.css'
import '../../styles/fonts.css'
import 'playbook-ui/dist/playbook.css'
import 'playbook-ui/dist/reset.css'
import '@fortawesome/fontawesome-free/js/fontawesome'
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDoubleDown, faLeaf, faUsers, faPiggyBank, faChartLine } from '@fortawesome/pro-regular-svg-icons'
import StreamFinancial from '../../components/StreamFinancial'
import StreamInnovations from '../../components/StreamInnovations'

library.add(faChevronDoubleDown, faLeaf, faUsers, faPiggyBank, faChartLine)

export default function Home({ domain }: { domain: string }) {
  return (
    domain === 'streamfinancial' ? <StreamFinancial /> : <StreamInnovations />
  )
}
