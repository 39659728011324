import React from 'react'
import Footer from './Footer'
import Hero from './Hero'
import Opportunities from './Opportunities'
import Leadership from './Leadership'
import Mission from "./Mission"

const StreamInnovations = () => {
  return (
    <div>
      <main>
        <Hero />
        <Opportunities />
        <Leadership />
        <Mission />
        <Footer />
      </main>
    </div>
  )
}

export default StreamInnovations;
